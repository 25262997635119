var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('label',{staticClass:"mb-1 fz-14",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('editor',{attrs:{"api-key":"cp1okc1v5mgid311kk2qxto3hn01wllmxcduuffi34b50rwr","init":{
      toolbar_mode: 'wrap',
      toolbar_sticky: true,
      selector: 'textarea',
      menubar: false,
      plugins:
        'autoresize print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable code',

      toolbar:
        'bold italic underline strikethrough  | alignleft aligncenter alignright alignjustify |  image media   link anchor  |outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | fontselect fontsizeselect formatselect code',

      max_height: _vm.height,
      min_height: _vm.minHeight,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      /* without images_upload_url set, Upload tab won't show up*/
      /* we override default upload handler to simulate successful upload*/
      browser_spellcheck: true,
      images_upload_handler: _vm.example_image_upload_handler,
    }},on:{"onchange":function($event){return _vm.change($event)},"onkeyUp":function($event){return _vm.change($event)},"onSelectionChange":_vm.handlerFunction},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }